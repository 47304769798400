<template>
	<a :class="type" rel="noopener noreferrer" @click.prevent="openBookingEngine({ ...$props })">
		<slot />
	</a>
</template>

<script setup>
// import BookingEngine from '@becurious/';
// let bookingengine = {};

defineProps({
	language: { type: String, default: '' },
	arrival: { type: String, default: '' },
	departure: { type: String, default: '' },
	pax: { type: String, default: '' },
	packageid: { type: String, default: '' },
	roomid: { type: String, default: '' },
	type: { type: String, default: '' },
});

const { locale } = useI18n();

const openBookingEngine = (options) => {
	if (!options.language) {
		options = { ...options, language: locale.value };
	}

	// window.open(bookingengine.getBookingUrl(options));
};

onMounted(() => {
	// bookingengine = new BookingEngine({});
});
</script>

<style lang="scss" scoped>
a {
	color: #fff;
	cursor: pointer;

	&.fixedmobile {
		display: none;
		width: 100%;
		position: fixed;
		bottom: 0;
		margin: 0;
		padding: 20px 0;
		z-index: 100;

		svg {
			margin-right: 5px;
		}
	}

	&:hover {
		text-decoration: none;
	}
}

@media (max-width: 600px) {
	a.fixedmobile {
		display: block;
	}
}
</style>
